<template>
  <component class="title" v-bind:class="as" :is="as">{{ title }}</component>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    as: String,
    title: String,
  },
};
</script>

<style scoped>
.title {
  @apply uppercase text-center mb-5 font-bold text-white drop-shadow-lg;
}

.h1,
.h2,
.h3 {
  @apply text-4xl md:text-6xl;
}
</style>
