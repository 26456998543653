<template>
  <div class="flex md:space-x-10 items-center">
    <img class="w-4/12 hidden md:block" :src="Wasserzeichen" alt="ISABBASO" />
    <div class="flex items-center flex-col h-full justify-center">
      <PageTitle as="h2" title="Die Band" class="rotate-3 page-title p-3" />
      <TextPlugin shortcode="dieband" />
      <OrangeButton
        v-if="showMoreButton"
        class="mt-3"
        :to="{ name: 'theband' }"
        text="Mehr über Uns"
      />
    </div>
  </div>
</template>

<script>
import Wasserzeichen from "../../assets/elch.png";

import TextPlugin from "@/components/Plugins/TextPlugin";
import PageTitle from "@/components/Title/PageTitle";
import OrangeButton from "@/components/Buttons/OrangeButton";
export default {
  name: "TheBand",
  components: { OrangeButton, PageTitle, TextPlugin },
  props: {
    showMoreButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Wasserzeichen: Wasserzeichen,
    };
  },
};
</script>

<style scoped>
.page-title {
  @apply bg-isabbaso_blue text-isabbaso_yellow;
}
</style>
