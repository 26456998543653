<template>
  <div class="inner" v-if="download.asset">
    <div class="download">
      <a :href="asset" target="_blank" class="title">
        <img
          v-if="download.asset.image"
          :src="thumb"
          class="graphic"
          :alt="download.title"
        />
        {{ download.title }}
        <div class="description" v-if="download.description">
          {{ download.description }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { api } from "@/data/api";

export default {
  name: "DownloadItem",
  props: {
    download: Object,
  },
  computed: {
    asset() {
      return api.download + this.download.asset.path;
    },
    thumb() {
      return api.thumbs + this.download.asset.path;
    },
  },
  methods: {},
};
</script>

<style scoped>
.download {
  @apply hover:underline p-5 inline-block cursor-pointer;
}
.title {
}
.description {
  @apply text-sm text-gray-500;
}

.graphic {
  @apply h-36 bg-gray-200 mx-auto bg-no-repeat bg-center bg-cover object-cover rounded-xl;
  width: 100%;
}
.inner {
  flex: 1 0 21%;
}
</style>
