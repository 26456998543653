<template>
  <SectionSpacer class="pt-20" :margin="true">
    <PageContainer>
      <PageTitle as="h1" title="Sponsoren- und Unterstützer Formular" />

      <template v-if="events && !loading">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 md:col-span-12">
            <label for="firma" class="label">Event</label>
            <select class="input" v-model="keySelect">
              <option
                v-for="(event, key) in events"
                :key="'event-select-' + event.title"
                :value="key"
              >
                {{ event.title }}
              </option>
            </select>
          </div>
          <div class="col-span-12 md:col-span-12">
            <label for="firma" class="label">Firma</label>
            <input type="text" class="input" v-model="form.firma" />
          </div>
          <div class="col-span-12 md:col-span-6">
            <label for="firma" class="label">Vorname</label>
            <input type="text" class="input" v-model="form.vorname" />
          </div>
          <div class="col-span-12 md:col-span-6">
            <label for="firma" class="label">Nachname</label>
            <input type="text" class="input" v-model="form.nachname" />
          </div>
          <div class="col-span-12 md:col-span-6">
            <label for="firma" class="label">Telefon</label>
            <input type="text" class="input" v-model="form.telefon" />
          </div>
          <div class="col-span-12 md:col-span-6">
            <label for="firma" class="label">Mail</label>
            <input type="text" class="input" v-model="form.mail" />
          </div>
          <div class="col-span-12 md:col-span-4">
            <label for="firma" class="label">Straße/Nr</label>
            <input type="text" class="input" v-model="form.strasse" />
          </div>
          <div class="col-span-12 md:col-span-4">
            <label for="firma" class="label">Postleitzahl</label>
            <input type="text" class="input" v-model="form.postleitzahl" />
          </div>
          <div class="col-span-12 md:col-span-4">
            <label for="firma" class="label">Stadt</label>
            <input type="text" class="input" v-model="form.stadt" />
          </div>
        </div>

        <template v-if="keySelect !== null">
          <div class="mt-8 mb-4">
            Wir / Ich unterstütze/n in diesem Jahr das Event:
            <strong>{{ getSelectedEvent.title }}</strong
            >. Unsere Unterstützung möchten wir wie folgt zusagen:
          </div>
          <div class="my-4">
            <select class="input" v-model="form.paket" @change="reset">
              <option value="1">Unterstützerpaket 1</option>
              <option value="2">Unterstützerpaket 2</option>
            </select>
            <CardWrapper class="mt-2">
              <template v-if="form.paket === '1'">
                Wir bestellen
                <input
                  type="text"
                  class="inline-input"
                  v-model="form.paket_wert"
                />
                Wertmarken zum Stückpreis a 5,00* € (Wert 2,50 €) und können
                selbst die Rückseite der Wertmarke mit einem Aufkleber, Stempel
                o.ä gestalten und an Kunden / Mitarbeiter / Freunde o.ä
                verteilen. <br /><br /><span class="text-sm"
                  >* der Preis ist höher als der eigentliche Wert. Dadurch wird
                  diese Veranstaltung unterstützt!</span
                >
              </template>
              <template v-else-if="form.paket === '2'">
                Wir möchten die Veranstaltung mit einer Summe von
                <input
                  type="text"
                  class="inline-input"
                  v-model="form.paket_wert"
                />
                Euro unterstützen.
              </template>
            </CardWrapper>
          </div>
          <div class="mt-10">
            Eine öffentliche Werbung mit unserem Logo ist:
            <div class="pl-3 mt-2">
              <input
                type="radio"
                v-model="form.werbung_oeffentlich"
                value="1"
                id="ra1"
                class="radio"
              />
              <label for="ra1">erwünscht</label><br />
              <input
                type="radio"
                value="0"
                v-model="form.werbung_oeffentlich"
                id="ra2"
                class="radio"
              />
              <label for="ra2">nicht erwünscht</label><br />
            </div>
          </div>
          <div class="text-center mt-10">
            <OrangeButton text="Jetzt unterstützen!" @click="submit" />
          </div>
        </template>
      </template>
      <template v-else-if="events !== null && events.length === 0 && !loading">
        Derzeit stehen keine Events zur Auswahl.
      </template>
      <template v-else>
        <TextLoader />
      </template>
    </PageContainer>
  </SectionSpacer>
</template>

<script>
import SectionSpacer from "@/components/Wrapper/SectionSpacer";
import { useMeta } from "vue-meta";
import PageContainer from "@/components/Wrapper/PageContainer";
import PageTitle from "@/components/Title/PageTitle";
import TextLoader from "@/components/Loader/TextLoader";
import CardWrapper from "@/components/Wrapper/CardWrapper";
import OrangeButton from "@/components/Buttons/OrangeButton";

export default {
  name: "FormView",
  components: {
    OrangeButton,
    CardWrapper,
    TextLoader,
    PageTitle,
    PageContainer,
    SectionSpacer,
  },
  data() {
    return {
      events: null,
      loading: false,
      keySelect: 0,
      form: {
        event: null,
        firma: null,
        vorname: null,
        nachname: null,
        telefon: null,
        mail: null,
        strasse: null,
        postleitzahl: null,
        stadt: null,
        paket: "1",
        paket_wert: null,
        werbung_oeffentlich: "1",
      },
    };
  },
  setup() {
    useMeta({
      title: "Sponsoren- und Unterstützer Formular",
    });
  },
  mounted() {
    this.init();
  },
  computed: {
    getSelectedEvent() {
      return this.events[this.keySelect];
    },
  },
  methods: {
    reset() {
      this.form.paket_wert = null;
    },
    init() {
      this.loadEvents();
    },
    submit() {
      this.form.event = this.getSelectedEvent.title;

      this.axios
        .post("/api/forms/submit/sponsoren_und_unterstuerterformular", {
          form: this.form,
        })
        .then(() => {
          alert("FERTIG!");
        });
    },
    loadEvents() {
      const today = new Date().toISOString().split("T")[0];

      this.axios
        .post("/api/collections/get/events_2", {
          sort: { date: 1 },
          filter: {
            date: { $gte: today },
          },
        })
        .then((r) => {
          this.events = r.data.entries;

          if (this.events.length > 0) {
            this.selected = this.events[0];
          }

          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
h1 {
  @apply text-4xl;
}

.input {
  @apply w-full p-3 rounded-xl shadow-sm border border-gray-400 bg-opacity-70 bg-white;
}

.label {
  @apply w-full uppercase text-sm font-bold;
}

.inline-input {
  @apply py-1 px-3 rounded-xl shadow-sm border border-gray-400;
}

.radio {
  @apply appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-amber-400 checked:border-amber-800 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer;
}
</style>
