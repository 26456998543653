<template>
  <template v-if="!events && loading">
    <EventGrid>
      <div class="loader"></div>
      <div class="loader"></div>
      <div class="loader"></div>
      <div class="loader"></div>
    </EventGrid>
  </template>
  <template v-else-if="!events && !loading">
    <AlertInfo text="Derzeit stehen keine Auftritte an." />
  </template>
  <template v-else>
    <EventGrid>
      <EventItem
        v-for="(event, index) in eventList"
        v-bind:key="'event-item-' + index"
        :event-item="event"
      />
    </EventGrid>
    <div v-if="showMore" class="mt-10 text-center">
      <OrangeButton text="Mehr anzeigen" :to="{ name: 'live' }" />
    </div>
  </template>
</template>

<script>
import EventItem from "@/components/Items/EventItem";
import EventGrid from "@/components/Wrapper/EventGrid";
import OrangeButton from "@/components/Buttons/OrangeButton";
import AlertInfo from "@/components/AlertInfo";
export default {
  name: "EventModule",
  components: { AlertInfo, OrangeButton, EventGrid, EventItem },
  props: {
    max: {
      type: Number,
      default: null,
    },
    showMore: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    eventList() {
      if (this.max) {
        return this.events.slice(0, this.max);
      }
      return this.events;
    },
  },
  data() {
    return {
      events: null,
      loading: true,
    };
  },
  mounted() {
    this.loadEvents();
  },
  methods: {
    loadEvents() {
      const today = new Date().toISOString().split("T")[0];

      this.axios
        .post("/api/collections/get/events_2", {
          sort: { date: 1 },
          filter: {
            date: { $gte: today },
          },
        })
        .then((r) => {
          this.events = r.data.entries;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.loader {
  @apply h-44 bg-gray-300 animate-pulse rounded;
}
</style>
