<template>
  <router-link
    class="link"
    :to="{ name: link.route }"
    v-if="!link.only || link.only === $route.name"
    >{{ link.name }}</router-link
  >
</template>

<script>
export default {
  name: "HeaderNavigationLink",
  props: {
    link: Object,
  },
};
</script>

<style scoped>
.link {
  @apply hover:scale-110 hover:rotate-3 transition-all duration-100 ease-linear border-b-2 border-transparent;
}
.router-link-exact-active {
  @apply rotate-3 font-bold scale-110 border-b-2 border-black;
}
</style>
