<template>
  <section v-if="videos && videos.length > 0">
    <PageTitle as="h2" title="Videos" />
    <PageContainer ref="videoplayer">
      <template v-if="allowed">
        <template v-if="videos">
          <VideoPlayer :videoid="videoid" />
          <div class="mt-3 playlist-grid">
            <VideoPlaylist
              v-if="videos.length > 1"
              :set-active-video="setActive"
              :active="videoid"
              :videos="videos"
            />
          </div>
        </template>
        <template v-else>
          <TextLoader />
        </template>
      </template>
      <template v-else>
        <CardWrapper>
          <AlertInfo
            text="Sie haben den Cookies für YouTube nicht zugestimmt."
          />
          <div class="text-center mt-3">
            <OrangeButton text="Cookies anpassen" @click="showConsent" />
          </div>
        </CardWrapper>
      </template>
    </PageContainer>
  </section>
</template>

<script>
import PageTitle from "@/components/Title/PageTitle";
import PageContainer from "@/components/Wrapper/PageContainer";
import VideoPlayer from "@/components/modules/VideoPlayer";
import VideoPlaylist from "@/components/modules/VideoPlaylist";
import TextLoader from "@/components/Loader/TextLoader";
import AlertInfo from "@/components/AlertInfo";
import OrangeButton from "@/components/Buttons/OrangeButton";
import CardWrapper from "@/components/Wrapper/CardWrapper";

export default {
  name: "VideoSection",
  components: {
    CardWrapper,
    OrangeButton,
    AlertInfo,
    TextLoader,
    VideoPlaylist,
    VideoPlayer,
    PageContainer,
    PageTitle,
  },
  computed: {
    allowed() {
      if (!window.klaro.getManager()) {
        return false;
      }
      return window.klaro.getManager().getConsent("youtube");
    },
  },
  data() {
    return {
      videoid: null,
      videos: null,
      manager: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    goto(refName) {
      const element = this.$refs[refName].$el;
      const top = element.offsetTop;
      window.scrollTo(0, top - 100);
    },
    setActive(videoid) {
      this.videoid = videoid;
      this.goto("videoplayer");
    },
    load() {
      this.axios.post("/api/collections/get/videos_2").then((r) => {
        this.videos = r.data.entries;

        if (this.videos.length > 0) {
          this.videoid = this.videos[0].youtube_video_id;
        }
      });
    },
    showConsent() {
      window.klaro.show();
    },
  },
};
</script>

<style scoped>
.youtube-info {
}
.playlist-grid {
  @apply flex flex-col flex-nowrap space-x-5;
}
</style>
