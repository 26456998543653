<template>
  <div class="member">
    <div
      ref="target"
      @mouseover="onMouseHover"
      @mouseout="onMouseLeave"
      class="image"
      id="image-container"
      :style="{ backgroundImage: 'url(' + image + ')' }"
    >
      <div
        :class="{
          'show-image': hover,
          'hide-image': !hover,
        }"
        @mouseover="onMouseHover"
        @mouseout="onMouseLeave"
        class="image"
        id="image-container"
        :style="{ backgroundImage: 'url(' + preview + ')' }"
      ></div>
    </div>
    <div class="name">{{ member.type }}: {{ member.name }}</div>
    <div class="nickname">{{ member.nickname }}</div>
  </div>
</template>

<script>
import { api } from "@/data/api";
import { ref } from "vue";
import { useElementVisibility } from "@vueuse/core";

export default {
  name: "BandMember",
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const target = ref(null);
    const targetIsVisible = useElementVisibility(target);

    return {
      target,
      targetIsVisible,
    };
  },
  watch: {
    // whenever question changes, this function will run
    targetIsVisible(newVisible) {
      if (this.$vssWidth < 576) {
        this.timer = setTimeout(() => {
          this.hover = !!newVisible;
        }, 700);
      }
    },
  },
  data() {
    return {
      hover: false,
      timer: null,
    };
  },
  methods: {
    onMouseHover() {
      if (this.$vssWidth >= 576) {
        this.hover = true;
      }
    },
    onMouseLeave() {
      if (this.$vssWidth >= 576) {
        this.hover = false;
      }
    },
  },
  computed: {
    getImage() {
      if (this.hover) {
        return this.image;
      }
      return this.preview;
    },
    image() {
      return api.thumbs + this.member.photo.path;
    },
    preview() {
      return api.thumbs + this.member.silhouette.path;
    },
  },
};
</script>

<style scoped>
.member {
  @apply flex flex-col justify-center items-center;
}
.image {
  @apply h-60 w-60 rounded-full bg-gray-200 mx-auto bg-no-repeat bg-center bg-cover object-cover;
}
.name {
  @apply text-isabbaso_yellow font-bold text-xl bg-isabbaso_blue inline-block py-1 px-2 -rotate-3 mb-2 -mt-8 rounded-xl;
}
.nickname {
  @apply text-gray-700 font-bold;
}

.show-image {
  opacity: 0;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  @apply border-2 border-gray-300;
}

.hide-image {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  @apply border-2 border-gray-300;
}

.image-container {
  background: center center no-repeat;
  background-size: contain;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
</style>
