<template>
  <FullPageImage
    ref="startMain"
    :src="Background"
    css="flex flex-col items-center text-center h-full justify-between"
  >
    <div class="mt-5 flex items-center flex-col pt-20 flex-1">
      <div
        class="flex items-center justify-center flex-col place-self-center flex-1"
      >
        <img :src="Schriftzug" alt="ISABBASO" class="schriftzug" />
        <OrangeButton text="AUF GEHTS" @click="letsstart" class="my-10" />
      </div>
      <img
        :src="Silhuetten"
        alt="ISABBASO"
        class="schriftzug place-self-center"
      />
    </div>
  </FullPageImage>
</template>

<script>
import FullPageImage from "@/components/modules/FullPageImage";
import OrangeButton from "@/components/Buttons/OrangeButton";
import Schriftzug from "../assets/ISABBASO_LOGO_TEXT.png";
import Silhuetten from "../assets/silhuetten_group.png";
import BG from "../assets/unnamed.png";

export default {
  name: "HomeStartSection",
  components: { OrangeButton, FullPageImage },
  data() {
    return {
      Schriftzug: Schriftzug,
      Background: BG,
      Silhuetten: Silhuetten,
    };
  },
  methods: {
    letsstart() {
      window.scroll({
        top: this.$refs.startMain.$el.clientHeight - 80,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.schriftzug {
  @apply w-10/12 sm:w-10/12 md:w-8/12 lg:w-6/12;
}
</style>
