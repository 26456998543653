<template>
  <div>
    <router-link :to="{ name: 'home' }">
      <img v-if="white" class="logo" :src="BandLogoWhite" alt="ISABBASO" />
      <img v-else class="logo" :src="BandLogo" alt="ISABBASO" />
    </router-link>
  </div>
</template>

<script>
import BandLogo from "../assets/logo_black_2.png";
import BandLogoWhite from "../assets/logo_white.2123c8a6.png";

export default {
  name: "BandLogo",
  props: {
    white: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      BandLogo: BandLogo,
      BandLogoWhite: BandLogoWhite,
    };
  },
};
</script>

<style scoped>
.logo {
  @apply h-10 md:h-12 w-auto;
}
</style>
