<template>
  <BGWrapper
    class="bg-black bg-opacity-90 text-white relative bg-parallax"
    v-bind:style="{ backgroundImage: 'url(' + Background + ')' }"
  >
    <PageTitle as="h2" title="Kontakt" />
    <PageContainer>
      <ContactModule />
    </PageContainer>
  </BGWrapper>
</template>

<script>
import BGWrapper from "@/components/Wrapper/BGWrapper";
import PageTitle from "@/components/Title/PageTitle";
import PageContainer from "@/components/Wrapper/PageContainer";
import ContactModule from "@/components/modules/ContactModule";
import Background from "@/assets/ISABBASO_backdrop.png";
export default {
  name: "ContactSection",
  components: { ContactModule, PageContainer, PageTitle, BGWrapper },
  data() {
    return {
      Background: Background,
    };
  },
};
</script>

<style scoped>
.orange-box {
  left: 50%;
  transform: translate(-50%, 0);
}
.bg-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @apply bg-cover;
}
</style>
