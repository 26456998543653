<template>
  <CardWrapper>
    <div class="date">{{ getEventDate }}</div>
    <div class="title">{{ eventItem.title }}</div>
    <div class="manager" v-if="eventItem.manager">
      <div>
        <span v-if="eventItem.place">{{ eventItem.place }}, </span
        >{{ eventItem.time_from }}
        <span v-if="eventItem.time_to">bis {{ eventItem.time_to }} Uhr</span>
      </div>
      <div>
        Veranstalter: <span class="font-bold">{{ eventItem.manager }}</span>
      </div>
    </div>
  </CardWrapper>
</template>

<script>
import moment from "moment";
import CardWrapper from "@/components/Wrapper/CardWrapper";

export default {
  name: "EventItem",
  components: { CardWrapper },
  props: {
    eventItem: Object,
  },
  computed: {
    getEventDate() {
      let date = moment(this.eventItem.date).format("DD.MM.YYYY");

      if (
        parseInt(moment(this.eventItem.date).format("YYYY")) ===
        new Date().getFullYear()
      ) {
        date = moment(this.eventItem.date).format("DD.MM");
      }
      return date;
    },
  },
};
</script>

<style scoped>
.title {
  @apply font-bold text-2xl;
}
.manager {
  @apply pt-2 mt-2 border-t border-gray-200 border-dashed;
}
.date {
  @apply text-isabbaso_yellow font-bold text-xl bg-isabbaso_blue inline-block py-1 px-2 -rotate-3 mb-2 rounded-xl;
}
</style>
