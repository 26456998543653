<template>
  <!-- Header | Desktop -->
  <header class="header" v-bind:class="getMoreCSS">
    <PageContainer class="desktop-inner">
      <!-- Logo -->
      <BandLogo :white="$route.name === 'home' && scrollY === 0" />
      <!-- Navigation -->
      <HeaderNavigation />
    </PageContainer>
  </header>
  <!-- Header | Mobil -->
  <header class="header-mobile" v-bind:class="getMoreCSS">
    <PageContainer class="mobile-inner">
      <!-- Mobiles Menü -->
      <MobileMenu :scroll="scrollY > 0" />
    </PageContainer>
  </header>
</template>

<script>
import PageContainer from "@/components/Wrapper/PageContainer";
import BandLogo from "@/partials/BandLogo";
import HeaderNavigation from "@/partials/HeaderNavigation";
import MobileMenu from "@/partials/MobileMenu";
export default {
  name: "AppHeader",
  components: { MobileMenu, HeaderNavigation, BandLogo, PageContainer },
  computed: {
    getMoreCSS() {
      if (this.$route.name !== "home") {
        /**
         * Hintergrundfarbe wenn es
         * nicht die Startseite ist.
         */
        return "bg-isabbaso_yellow bg-opacity-95 shadow-2xl";
      }
      if (this.scrollY > 0) {
        /**
         * Hintergrundfarbe wenn der
         * Benutzer gescrollt hat.
         */
        return "bg-isabbaso_yellow bg-opacity-95 shadow-2xl";
      }

      // Keine weitere CSS Klasse
      return "!text-white !border-red";
    },
  },
  data() {
    return {
      scrollY: 0, // Scroll Position Y
    };
  },
  mounted() {
    // Scroll Position setzen.
    this.scrollY = window.scrollY;
  },
  created() {
    // Scroll Event Listener hinzufügen
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    // Scroll Event Listener entfernen.
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      /**
       * Beim Scollen die Position
       * zwischenspeichern.
       */
      this.scrollY = window.scrollY;
    },
  },
};
</script>

<style scoped>
.header {
  @apply z-40 fixed top-0 left-0 transition-all duration-150 ease-linear right-0 border-b md:block hidden border-b border-transparent;
}
.desktop-inner {
  @apply items-center justify-between flex h-20;
}

.mobile-inner {
  @apply items-center justify-between flex h-20;
}

.header-mobile {
  @apply flex md:hidden z-40 fixed transition-all duration-150 ease-linear top-0 left-0 right-0 border-b border-transparent;
}
</style>
