<template>
  <BandLogo :white="!scroll && $route.name === 'home'" />
  <div
    class="hamburger-icon-container"
    :class="{ 'burger-white': !scroll && $route.name === 'home' }"
    @click="toggle"
    v-if="!showOverlay"
  >
    <div class="hamburger-icon"></div>
  </div>
  <MobileMenuOverlay v-if="showOverlay" :close="toggle" />
</template>

<script>
import BurgerIcon from "../assets/images/icons/menu-icon.svg";
import BandLogo from "@/partials/BandLogo";
import MobileMenuOverlay from "@/partials/MobileMenuOverlay";
import { BodyScroll } from "@/mixins/BodyScroll";

export default {
  name: "MobileMenu",
  components: { MobileMenuOverlay, BandLogo },
  mixins: [BodyScroll],
  props: {
    scroll: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      burger: BurgerIcon,
      showOverlay: false,
    };
  },
  methods: {
    toggle() {
      this.showOverlay = !this.showOverlay;

      if (!this.showOverlay) {
        this.showScrollbar();
      } else {
        this.hideScrollbar();
      }
    },
  },
};
</script>

<style scoped>
.burger {
  @apply h-4 cursor-pointer;
}

.hamburger-icon-container {
  @apply cursor-pointer;
  height: 1.7em;
  width: 1.7em;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
}

.burger-white .hamburger-icon:after,
.burger-white .hamburger-icon:before,
.burger-white .hamburger-icon {
  background: white !important;
}

.hamburger-icon,
.hamburger-icon:before,
.hamburger-icon:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 1.7em;
  background: black;
  transition: all 0.2s ease;
  @apply rounded-xl;
}

.hamburger-icon {
  top: 0.75em;
}

.hamburger-icon:before {
  top: -0.55em;
}

.hamburger-icon:after {
  top: 0.55em;
}
</style>
